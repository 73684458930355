import React from "react";
import { Link } from "react-router-dom";
import GardientButton from "../utilities/GardientButton";
import { motion } from "framer-motion";
const Mission = () => {
  const variants = {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.8,
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
      },
    },
  };
  return (
    <div className="bg-gradient-to-b from-gray-100 via-slate-50 to-gray-300">
      <motion.div
        variants={variants}
        initial={"hidden"}
        whileInView={"show"}
        class="text-center block relative z-20 mx-auto pt-8"
      >
        <motion.h1
          variants={item}
          class="text-5xl font-light my-2 text-gray-900 font-bcf"
        >
          Empowering Wellness Through Nature
        </motion.h1>
        <motion.p
          variants={item}
          className="text-lg w-full md:w-3/4 mx-auto font-light p-3 text-gray-800 font-calibri"
        >
          At REDSTAM, we fuse ancient wisdom with modern science to unlock
          nature's secrets for vibrant living. Our ethical practices ensure
          harmony with the planet, while our products empower individuals to
          thrive in body, mind, and soul. Join us on the journey to holistic
          well-being.
        </motion.p>
        <motion.div variants={item} className="py-4">
          <Link to="/">
            <GardientButton text={"Shop Now"} />
          </Link>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Mission;
