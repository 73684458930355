import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import MetaData from "../Layouts/Metadata";

import AdminLayout from "../Layouts/AdminLayout";
import {
  useDeleteOrderMutation,
  useGetAdminOrdersQuery,
} from "../../redux/api/orderApi";
import "../orders/my-order.css";
import SkeletonText from "../utilities/SkeletonText";
import DeleteOrder from "../utilities/DeleteOrder";

const ListOrders = () => {
  const { data, isLoading, error } = useGetAdminOrdersQuery();
  const [isConfirmModalOpen,setIsConfirmModalOpen]=useState(false);
  const [selectedOrderId,setSelectedOrderId]=useState('');
  const [selectedOrderName,setSelectedOrderName]=useState('');

  useEffect(() => {
    if (error) {
      toast.error(error?.data?.message);
    }
    console.log(data,'datas');
    
  }, [data, error]);

  // const deleteOrderHandler = (id) => {
  //   deleteOrder(id);
  // };

  const openConfirm = (id, name) => {
    setIsConfirmModalOpen(true);
    setSelectedOrderId(id);
    setSelectedOrderName(name);
  };
  const closeConfirm = () => {
    setIsConfirmModalOpen(false);
    setSelectedOrderId(null);
    setSelectedOrderName(null);
  };

  const handleClose = ()=>{
    closeConfirm()
  }

  const setOrders = () => {
    const orders = {
      columns: [
        {
          label: "ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Payment Status",
          field: "paymentStatus",
          sort: "asc",
        },
        {
          label: "Order Status",
          field: "orderStatus",
          sort: "asc",
        },

        {
          label: "Actions",
          field: "actions",
          sort: "asc",
        },
      ],
      rows: [],
    };

    data?.orders?.forEach((order) => {
      orders.rows.push({
        id: order?._id,
        paymentStatus: order?.paymentInfo?.status?.toUpperCase(),
        orderStatus: order?.orderStatus,
        actions: (
          <>
            <Link
              to={`/admin/orders/${order?._id}`}
              className="btn btn-outline-primary"
            >
              <i className="fa fa-pencil"></i>
            </Link>

            <button
              className="btn btn-outline-danger ms-2"
              onClick={() => openConfirm(order?._id)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </>
        ),
      });
    });

    return orders;
  };

  if (isLoading)
    return (
      <AdminLayout>
        <div className="flex justify-center items-center w-full h-screen">
          <SkeletonText />
        </div>
      </AdminLayout>
    );

  return (
    <AdminLayout>
      <MetaData title={"All Orders"} />

      <h1 className="my-5">{data?.orders?.length} Orders</h1>

      <MDBDataTable
        data={setOrders()}
        className="px-3"
        bordered
        striped
        hover
      />
      {isConfirmModalOpen&&(
        <DeleteOrder orderId={selectedOrderId} orderName={selectedOrderName} onClose={handleClose} />
      )}
    </AdminLayout>
  );
};

export default ListOrders;
