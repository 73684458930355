const banners = [
  "https://ik.imagekit.io/c1jhxlxiy/Banners/1.jpg?updatedAt=1712654590080",
  "https://ik.imagekit.io/c1jhxlxiy/Banners/2.jpg?updatedAt=1712654590124",
  "https://ik.imagekit.io/c1jhxlxiy/Banners/3.jpg?updatedAt=1712654589887",
  "https://ik.imagekit.io/c1jhxlxiy/Banners/4.jpg?updatedAt=1712654589969",
  "https://ik.imagekit.io/c1jhxlxiy/Banners/5.jpg?updatedAt=1712654590083",
];


// const banners = [
//   {
//     src: "https://ik.imagekit.io/c1jhxlxiy/Banners/1.jpg?updatedAt=1712654590080",
//     link: "/products/6632450450b3e83d0b476637",
//   },
//   {
//     src: "https://ik.imagekit.io/1ebcdgswb/inherbz%20banner/banner-2.jpeg?updatedAt=1718216965453",
//     link: "/products/6642247f2caa481cc3839db8",
//   },
//   {
//     src: "https://ik.imagekit.io/1ebcdgswb/inherbz%20banner/banner-3.jpeg?updatedAt=1718216965413",
//     link: "/products/663245e150b3e83d0b476640",
//   },
//   {
//     src: "https://ik.imagekit.io/1ebcdgswb/inherbz%20banner/banner-4.jpeg?updatedAt=1718216965388",
//     link: "/products/663f38b9f35f7a395cc76342",
//   },
// ];
export default banners;
